<!DOCTYPE html>
<html>
<head>

</head>
<body>
  <div id="pattern"></div>
  <div class="background">
    <video playsinline="playsinline" autoplay="autoplay" [muted]="true" loop="loop" id="video">
            <source src="assets/vid.mp4" type="video/mp4">
            <script>
                var video = document.getElementById("video");

                app.videoElement = video;
                app.videoElement.volume = 0;

                document.getElementById('video').play();
            </script>
    </video>
  </div>
  
  <div class="main" fxLayout="column" fxLayoutAlign="center center"> 
    <div id="text">Alexander Jeuk</div>
    <div id="subtext">Student - TU Ilmenau</div>
    <div id="contacts"fxLayout="row" fxLayoutAlign="center center">
      <a href="http://www.linkedin.com/in/alexander-jeuk-8931b6171"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
      <a href="https://github.com/Alex3434"><i class="fa fa-github" aria-hidden="true"></i></a>
      <a href="https://t.me/datalex101"><i class="fa fa-telegram" aria-hidden="true"></i></a>
      <a href="https://steamcommunity.com/id/ILoveSumSchnitzel"><i class="fa fa-steam" aria-hidden="true"></i></a>
      <a href="https://open.spotify.com/user/datalex%21?si=wyTqEvgISfOIWI2daJyQbA"><i class="fa fa-spotify" aria-hidden="true"></i></a>
      <a href="https://discordhub.com/profile/606887736049139712"><i class="fa fa-discord-alt" aria-hidden="true"></i></a>
    </div>
  </div>
</body>
</html>
