import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import * as favicon from "favicon.js";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "my-app";

  public constructor(private titleService: Title) {}

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  counter = 10;
  animated_title = "▄■▀■▄■▀■▄■▀■▄■▀■▄■·ALEX·■▄■▀■▄■▀■▄■▀■▄■▀■▄";
  async background_task() {
    while (true) {
      this.titleService.setTitle(
        this.animated_title.substring(this.counter, this.counter + 18)
      );

      if (this.counter + 18 > this.animated_title.length) {
        this.counter = 0;
      }

      this.counter += 1;
      await this.sleep(200);
    }
  }

  ngAfterViewInit() {
    favicon.animate(
      [
        "../assets/frame_00_delay-0.05s.gif",
        "../assets/frame_01_delay-0.05s.gif",
        "../assets/frame_02_delay-0.05s.gif",
        "../assets/frame_03_delay-0.05s.gif",
        "../assets/frame_04_delay-0.05s.gif",
        "../assets/frame_05_delay-0.05s.gif",
        "../assets/frame_06_delay-0.05s.gif",
        "../assets/frame_07_delay-0.05s.gif",
        "../assets/frame_08_delay-0.05s.gif",
        "../assets/frame_09_delay-0.05s.gif",
      ],
      80
    );

    this.background_task();
  }
}
